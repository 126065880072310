<template>
  <div>
    <Report module="Financeiro" name="Pagar e Receber" urlGet="/api/v1/finance/report/payable-receivable"
      :header="header" :filters="filters" />
  </div>
</template>

<script>

import Report from "@nixweb/nixloc-ui/src/component/report/Report";

export default {
  name: "ReportPayableReceivable",
  components: {
    Report
  },
  data() {
    return {
      header: [
        {
          title: "Tipo",
          field: "typeName",
          fieldOrderBy: "TypeName",
          show: true,
          styleHeader: "width:60px;",
          styleBody: "max-width: 50px;",
        },
        {
          title: "Data Cadastro",
          field: "registeredDateStr",
          fieldOrderBy: "RegisteredDate",
          show: false,
          styleHeader: "width:100px;",
          styleBody: "max-width: 100px;",
        },
        {
          title: "Destinatário",
          field: "recipientName",
          fieldOrderBy: "RecipientName",
          show: true,
          styleHeader: "width:250px;",
          styleBody: "max-width: 240px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Descrição",
          field: "description",
          fieldOrderBy: "Description",
          show: true,
          styleHeader: "width:250px;",
          styleBody: "max-width: 240px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Doc",
          field: "document",
          fieldOrderBy: "Document",
          show: false,
          styleHeader: "width:50px;",
          styleBody: "max-width: 50px;",
        },
        {
          title: "Pagamento",
          field: "typePaymentName",
          fieldOrderBy: "TypePaymentName",
          show: true,
          styleHeader: "width:120px;",
          styleBody: "max-width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "C Custo",
          field: "costCenterName",
          fieldOrderBy: "CostCenterName",
          show: false,
          styleHeader: "width:160px;",
          styleBody: "max-width: 140px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "P Conta",
          field: "chartOfAccountsName",
          fieldOrderBy: "ChartOfAccountsName",
          show: true,
          styleHeader: "width:160px;",
          styleBody: "max-width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Conta Bancária",
          field: "bankAccountName",
          fieldOrderBy: "BankAccountName",
          show: false,
          styleHeader: "width:150px;",
          styleBody: "max-width: 150px;",
        },
        {
          title: "Dt Vcto",
          field: "dueDateStr",
          fieldOrderBy: "DueDate",
          show: true,
          styleHeader: "width:100px;",
          styleBody: "max-width: 100px;",
          initialOrderBy: true,
        },
        {
          title: "Dt Compe...",
          field: "competenceDateStr",
          fieldOrderBy: "CompetenceDate",
          show: false,
          styleHeader: "width:100px;",
          styleBody: "max-width: 100px;",
        },
        {
          title: "Dt Concil...",
          field: "conciliationDateStr",
          fieldOrderBy: "ConciliationDate",
          show: false,
          styleHeader: "width:100px;",
          styleBody: "max-width: 100px;",
        },
        {
          title: "Status",
          field: "status",
          fieldOrderBy: "Status",
          show: false,
          styleHeader: "width:80px;",
          styleBody: "max-width: 800px;",
        },
        {
          title: "R$ Valor",
          field: "valueStr",
          fieldOrderBy: "Value",
          show: false,
          styleHeader: "width:120px;",
          styleBody: "max-width: 120px;",
        },
        {
          title: "R$ Desconto",
          field: "discountStr",
          fieldOrderBy: "Discount",
          show: false,
          styleHeader: "width:120px;",
          styleBody: "max-width: 120px;",
        },
        {
          title: "R$ Multa",
          field: "feesStr",
          fieldOrderBy: "Fees",
          show: false,
          styleHeader: "width:120px;",
          styleBody: "max-width: 120px;",
        },
        {
          title: "R$ T.Geral",
          field: "generalStr",
          fieldOrderBy: "General",
          show: true,
          styleHeader: "width:120px;",
          styleBody: "max-width: 120px;",
        },
      ],
      filters: [
        {
          type: "multiOptions",
          title: "Centro de Custo",
          field: "CostCenterName",
          url: "/api/v1/finance/cost-center/select-all",
          propsParams: {},
          value: "",
        },
        {
          type: "multiOptions",
          title: "Conta Bancária",
          field: "BankAccountName",
          url: "/api/v1/finance/bank-account/select-all",
          propsParams: {},
          value: "",
        },
        {
          type: "date",
          title: "Data de Cadastro",
          field: "RegisteredDate",
          value: "",
          required: true,
        },
        {
          type: "date",
          title: "Data de Competência",
          field: "CompetenceDate",
          value: "",
          required: true,
        },
        {
          type: "date",
          title: "Data de Conciliação",
          field: "ConciliationDate",
          value: "",
          required: true,
        },
        {
          type: "date",
          title: "Data de Vencimento",
          field: "DueDate",
          value: "",
          required: true,
        },
        {
          type: "multiOptions",
          title: "Destinatário",
          field: "RecipientName",
          url: "/api/v1/finance/payable-receivable/select-all-recipient",
          propsParams: {},
          value: "",
        },
        {
          type: "text",
          title: "Documento",
          field: "Document",
          value: "",
        },
        {
          type: "options",
          title: "Pagar ou Receber",
          field: "TypeName",
          options: [
            { text: "Pagar", value: "Pagar" },
            { text: "Receber", value: "Receber" },
          ],
          value: [],
        },
        {
          type: "multiOptions",
          title: "Plano de Conta",
          field: "ChartOfAccountsName",
          url: "/api/v1/finance/chart-of-accounts/select-all",
          propsParams: {},
          value: "",
        },
        {
          type: "options",
          title: "Pago/Recebido",
          field: "PaidName",
          options: [
            { text: "Sim", value: "Sim" },
            { text: "Não", value: "Não" },
          ],
          value: [],
        },
        {
          type: "options",
          title: "Status",
          field: "Status",
          options: [
            { text: "Agendada", value: "Agendada" },
            { text: "Pago", value: "Pago" },
            { text: "Recebido", value: "Recebido" },
            { text: "Pendente", value: "Pendente" },
          ],
          value: [],
        },
        {
          type: "multiOptions",
          title: "Tipo de Pagamento",
          field: "TypePaymentName",
          url: "/api/v1/finance/type-payment/select-all",
          propsParams: {},
          value: "",
        },
        {
          type: "decimal",
          title: "Valor",
          field: "Value",
          valueStart: 0,
          valueEnd: 0,
        },
        {
          type: "decimal",
          title: "Desconto",
          field: "Discount",
          valueStart: 0,
          valueEnd: 0,
        },
        {
          type: "decimal",
          title: "Multa",
          field: "Fees",
          valueStart: 0,
          valueEnd: 0,
        },
        {
          type: "decimal",
          title: "Geral",
          field: "General",
          valueStart: 0,
          valueEnd: 0,
        },
      ],
    }
  }
}


</script>